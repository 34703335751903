import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

// markup
const NotFoundPage = () => {
	useEffect(() => {
		navigate('/');
	}, []);
	return (
		<h1 style={{ textAlign: 'center', padding: '4rem' }}>
			Page not found. Redirecting to homepage.
		</h1>
	);
};

export default NotFoundPage;
